import React from 'react';
import { elastic as Menu } from 'react-burger-menu';
import './Sidebar.css';

import burger from '../../assets/burger.svg';

function Sidebar() {
  return (
    <Menu noOverlay right customBurgerIcon={ <img alt="burger" src={burger} /> }>
      <a className="menu-item" href="/#home">
        Home
      </a>
      <a className="menu-item" href="/#aboutMe">
        About Me
      </a>
       <a className="menu-item" href="/#caseStudies">
        Case Studies
      </a>
      <a className="menu-item" href="/#work">
        My Work
      </a>
      <a className="menu-item" target="blank" href="/blog">
        My Blog
      </a>
      {/* <a className="menu-item" target="blank" href="/uniqlo">
        Uniqlo
      </a> */}

    </Menu>
  );
}

export default Sidebar;

