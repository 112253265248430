import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Wave from 'react-wavify'

import sunbridge from '../assets/sunbridge.png';
import wcsr from '../assets/wcsr.png';
import liberi from '../assets/liberi.png';

function Work() {
  return (
    <section id="work" style={{backgroundColor:'#267180', marginTop: "-10px"}}>
        <Box>
          <Wave fill='#1F5A66'
          paused={false}
          options={{
              height: 20,
              amplitude: 45,
              speed: 0.21,
              points: 5
          }}
          />
        </Box>

        <Box sx={{ flexGrow: 1, marginTop: '-10px', paddingTop: '10px', paddingLeft: '30px', paddingRight: '30px', backgroundColor: "#1F5A66"}}>

          <h1 style={{color: "#ffff", marginTop: "-20px"}}>
                  My Work
          </h1>

          <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 5}} justifyContent="center" alignItems="top">
              <Grid item lg={6} md={6} s={12} xs={12}>
                <img alt="sunbridge" style={{height: '320px'}} src={sunbridge}></img>

                <h2 style={{margin: 0, color: '#ffff'}}>Sunbridge</h2>
                <p style={{color: '#ffff'}}>Built a website for an E-Commerce Bookstore</p>
              </Grid>

              <Grid item lg={6} md={6} s={12} xs={12}>
               <img alt="sunbridge" style={{height: '320px'}} src={wcsr}></img>

                <h2 style={{margin: 0, color: '#ffff'}}>Western Circuit Sailing Regatta</h2>
                <p style={{color: '#ffff'}}>Built a fundraising website where we raised over 13k for Sailability!</p>
              </Grid>

              <Grid item lg={6} md={6} s={12} xs={12}>
               <img alt="liberi" style={{height: '320px'}} src={liberi}></img>

                <h2 style={{margin: 0, color: '#ffff'}}>Liberi Mobile App</h2>
                <p style={{color: '#ffff'}}>Designed a full-fledged social networking platform</p>
              </Grid>

          </Grid>
        </Box>
    </section>

  )
}

export default Work
