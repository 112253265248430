import React from "react";

import Home from "../sections/Home";
import AboutMe from "../sections/AboutMe";
import CaseStudies from "../sections/CaseStudies";
import Work from "../sections/Work";
import Footer from "../sections/Footer";

function Full() {
    return (
    <div id="page-wrap">
        <Home></Home>
        <AboutMe></AboutMe>
        <CaseStudies></CaseStudies>
        <Work></Work>
        <Footer></Footer>
    </div>

  );
};

export default Full;