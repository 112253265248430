import styled from 'styled-components'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

// images
import diver from '../assets/diver.PNG';

export default function AboutMe() {

  return (
    <section id="aboutMe" style={{backgroundColor:'#2E8799', marginTop: "-10px"}}>

      <Box sx={{ flexGrow: 1, marginTop: '-10px', textAlign: 'left', paddingLeft: '30px', paddingRight: '30px'}}>
        
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 5}} justifyContent="center" alignItems="center">
            <Grid item lg={6} md={6} s={12} xs={12}>
              <h1 style={{color: "#ffff", marginTop: "-20px"}}>
                Get to know me
              </h1>
              <h2 style={{margin: 0, color: '#ffff'}}>What inspired me?</h2>
              <p style={{color: '#ffff'}}>
                In 2019, I took a gap year to intern at <span style={{color: '#fbe9a8', fontWeight: 700}}>Wonder Workshop</span> in Silicon Valley. In 2020, I graduated from <span style={{color: '#fbe9a8', fontWeight: 700}}>General Assembly's UX Design Immersive boot camp.</span> After a tumultuous year of self discovery, I found a calling in the tech industry. Interestingly, I joined <span style={{color: '#fbe9a8', fontWeight: 700}}>TechForShe</span> as a mentor and facilitator in hopes of bridging the gap for women who want to break into the Tech Industry. Currently, I'm pursuing a degree in <span style={{color: '#fbe9a8', fontWeight: 700}}>Information Systems and Computing</span> in Singapore Management University.
              </p>

              <h2 style={{margin: 0, color: '#ffff'}}>My Unique Selling Point:</h2>
              <p style={{color: '#ffff'}}>
                With a background in <span style={{color: '#fbe9a8', fontWeight: 700}}>Digital Marketing and International Business,</span> I understand the importance of finding the right balance between business goals and user needs. Thus, I believe every design is intentional with a unique Call To Action (CTA). Additionally, my knowledge on <span style={{color: '#fbe9a8', fontWeight: 700}}>web development</span> makes me a better <span style={{color: '#fbe9a8', fontWeight: 700}}>UX designer</span> as I am able to understand the design constraints based on the team's developmental capabilities and project timeline.
              </p>

              <h2 style={{margin: 0, color: '#ffff'}}>Personal philosophy:</h2>
              <p style={{color: '#ffff'}}>
                Being a firm believer in <span style={{color: '#fbe9a8', fontWeight: 700}}>pushing our limits</span> and <span style={{color: '#fbe9a8', fontWeight: 700}}>knowing what we don't know,</span> I put that into practice by self imposing a rule - to <span style={{color: '#fbe9a8', fontWeight: 700}}>learn something new every single day,</span> no matter how big or small.
              </p>
            </Grid>

            <Grid item lg={4} md={6} s={12} xs={12}>
              <img alt="diver" style={{height: '400px', textAlign: 'right'}} src={diver}></img>
            </Grid>
          </Grid>


      </Box>

    </section>
  );


  }
