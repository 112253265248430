import React from 'react';
// import './CaseStudies.css';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { styled, theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import Wave from 'react-wavify'
import { Button, CardActionArea, CardActions } from '@mui/material';

// images

import uniqloPoster from '../assets/uniqlo/uniqloPoster.jpg';
import catSocratesPoster from '../assets/catSocrates/catSocratesPoster.png';
import ucarsPoster from '../assets/ucars/ucarsPoster.png';


function CaseStudies() {
    const navigate = useNavigate();

    function navigateUniqlo() {
    navigate("uniqlo");
    }

    function navigateUcars() {
    navigate("ucars");
    }

    function navigateCatSocrates() {
    navigate("catSocrates");
    }

    return (
    <section id="caseStudies" style={{backgroundColor:'#2E8799', marginTop:"-10px"}}>
        <Box>
          <Wave fill='#267180'
          paused={false}
          options={{
              height: 20,
              amplitude: 45,
              speed: 0.21,
              points: 5
          }}
          />
        </Box>

        <Box style={{backgroundColor: '#267180'}}>

        <Container>
            <div>
                <h1 style={{color: "#ffff", marginTop: "-20px"}}>
                Case Studies
                </h1>

                <Box sx={{ flexGrow: 1}}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 2 }} justifyContent="center" alignItems="space-around" textAlign="left">

                        {/* Uniqlo */}
                        <Grid item lg={4} md={5} s={12}>
                            <div onClick={navigateUniqlo}>
                                <Card sx={{ maxWidth: 400, minHeight: 300, margin: '0px', background: '#fffff',boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',backdropFilter: 'blur(20px)', webkitBackdropFilter: 'blur(20px)', border: '1px solid rgba(255, 255, 255, 0.24)'}}>
                                    <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="240"
                                        image={uniqloPoster}
                                        sx={{maxWidth: 400, minHeight: 300, margin: '0px', padding: '20px'}}
                                        />
                                        <CardContent sx={{marginTop: '-20px', color: '#267180'}}>
                                            <h3>Responsive Uniqlo</h3>
                                        </CardContent>

                                        <CardContent sx={{marginTop: '-40px', color: '#267180'}}>
                                        <p>
                                            Revolutionizing the way we shop from the comforts of our home
                                        </p>

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </Grid>

                        {/* uCars */}
                        <Grid item lg={4} md={5} s={12}>
                            <div onClick={navigateUcars}>
                                <Card sx={{ maxWidth: 400, minHeight: 300, margin: '0px', background: '#fffff',boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',backdropFilter: 'blur(20px)', webkitBackdropFilter: 'blur(20px)', border: '1px solid rgba(255, 255, 255, 0.24)'}}>
                                    <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="240"
                                        image={ucarsPoster}
                                        sx={{maxWidth: 400, minHeight: 300, margin: '0px', padding: '20px'}}
                                        />
                                        <CardContent sx={{marginTop: '-20px', color: '#267180'}}>
                                            <h3>UCars Go Mobile</h3>
                                        </CardContent>

                                        <CardContent sx={{marginTop: '-40px', color: '#267180'}}>
                                        <p>
                                            A seamless mobile experience for the automotive industry
                                        </p>

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>

                        </Grid>

                        {/* Cat Socrates */}
                        <Grid item lg={4} md={5} s={12}>
                            <div onClick={navigateCatSocrates}>
                                <Card sx={{ maxWidth: 400, minHeight: 300, margin: '0px', background: '#ffff',boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',backdropFilter: 'blur(20px)', webkitBackdropFilter: 'blur(20px)', border: '1px solid rgba(255, 255, 255, 0.24)'}}>

                                    <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="240"
                                        image={catSocratesPoster}
                                        sx={{maxWidth: 400, minHeight: 300, margin: '0px', padding: '20px'}}
                                        />

                                        <CardContent sx={{marginTop: '-20px', color: '#267180'}}>
                                            <h3>Cat Socrates</h3>
                                        </CardContent>

                                        <CardContent sx={{marginTop: '-40px', color: '#267180'}}>
                                        <p>
                                            Removing the barriers of e-commerce one click at a time
                                        </p>

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>

                        </Grid>

                    </Grid>
                </Box>




            </div>
        </Container>
        </Box>
    </section>
  )
}

export default CaseStudies
