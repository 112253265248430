import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from './components/Sidebar/Sidebar.jsx';
import Full from "./pages/Full";

import Blog from "./pages/Blog";
import SiliconValley from "./pages/SiliconValley";

import Uniqlo from "./pages/Uniqlo";
import Ucars from "./pages/Ucars";
import CatSocrates from "./pages/CatSocrates";

function App() {
  return (
    <BrowserRouter>
      <div className="App" id="outer-container">
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <div id="page-wrap">
          {/* <Full></Full> */}

          {/* <MyThoughts></MyThoughts> */}

          <Routes>
            <Route exact path="/" element={<Full />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/siliconValley" element={<SiliconValley />} />
            <Route exact path="/uniqlo" element={<Uniqlo />} />
            <Route exact path="/ucars" element={<Ucars />} />
            <Route exact path="/catSocrates" element={<CatSocrates />} />
        </Routes>
        </div>




      </div>
    </BrowserRouter>
  );
}

export default App;

