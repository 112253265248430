import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './Uniqlo.css';

import tool from '../assets/tool.png';
import lightbulb from '../assets/lightbulb.png';
import survey from '../assets/uniqlo/survey.png';
import affinityMap from '../assets/uniqlo/affinityMap.png';
import persona from '../assets/uniqlo/persona.png';
import problemStatement from '../assets/uniqlo/problemStatement.png';
import competitiveFeature from '../assets/uniqlo/competitive_featureInventory.png';
import competitivePlusDelta from '../assets/uniqlo/competitive_PlusDelta.png';
import comparativeAnalysis from '../assets/uniqlo/comparativeAnalysis.png';
import heauristicEvaluation_scheme2 from '../assets/uniqlo/HeauristicEvaluation_scheme2.png';
import he_Success from '../assets/uniqlo/HE_Success.png';
import he_Problem from '../assets/uniqlo/HE_Problem.png';
import moscow_existing from '../assets/uniqlo/MOSCOW_existingfeatures.png';
import moscow_new from '../assets/uniqlo/MOSCOW_newfeatures.png';
import IA_Similaritymatrix from '../assets/uniqlo/IA_Similaritymatrix.png';
import siteMap_Existing from '../assets/uniqlo/siteMap_Existing.png';
import siteMap_New from '../assets/uniqlo/siteMap_New.png';
import userFlow1 from '../assets/uniqlo/userFlow1.png';
import userFlow2 from '../assets/uniqlo/userFlow2.png';
import uniqloPoster from '../assets/uniqlo/uniqloPoster.jpg';
import sketch1 from '../assets/uniqlo/sketch1.png';
import sketch2 from '../assets/uniqlo/sketch2.png';
import moodboard from '../assets/uniqlo/moodboard.png';
import styleGuide from '../assets/uniqlo/styleGuide.png';
import initialWireframes from '../assets/uniqlo/initialWireframes.png';
import seqSusScore from '../assets/uniqlo/seqSusScore.png';
import mockup_availabilityReservation from '../assets/uniqlo/mockup_availabilityReservation.png';
import mockup_fittingRoom from '../assets/uniqlo/mockup_fittingRoom.png';
import mockup_homePage from '../assets/uniqlo/mockup_homePage.png';
import mockup_ProductBarcodeScanner from '../assets/uniqlo/mockup_ProductBarcodeScanner.png';


function Uniqlo() {
  return (
    <section>

        <Box className="box" sx={{ flexGrow: 1, paddingTop: '60px'}}>
            <Grid sx={{textAlign: "center"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>UNIQLO's Responsive Redesign</h2>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p style={{marginTop: "0px"}}>Revolutionizing the way we shop from the comforts of our home</p>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <img className="portfolio_img" alt="uniqlo" src={uniqloPoster}></img>
                </Grid>
            </Grid>

            <Grid sx={{textAlign: "left", paddingTop: "20px", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top" backgroundColor="#f1f1f1">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Challenge</h3>
                    <p>UNIQLO is a Global Japanese casual wear, retailer, manufacturer and designer. The objective of this project was to understand what users need from the app and identify the possible business opportunities which the business could leverage on to redesign the shopping experience.</p>
                </Grid>
                <Grid item lg={6} md={6} s={6} xs={12}>
                    <h3 style={{margin: "0px"}}>Deliverables</h3>
                    <p>Redesign UNIQLO's mobile app and website in a span of two weeks</p>
                </Grid>

                <Grid item lg={6} md={6} s={6} xs={12}>
                    <h3 style={{margin: "0px"}}>Roles</h3>
                    <p>Worked in a team of 3 <br></br>
                        UX Researcher<br></br>
                        UX Information Architect<br></br>
                        Facilitated Prototyping & Testing</p>
                </Grid>
            </Grid>

            {/* Discover */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>1 - Discover</h2>
                    <p>Before we started, we had to first uncover the pain points faced by users when using these interfaces. We conducted <span style={{color: '#E5735C', fontWeight: 700}}>user surveys and user interviews</span> to help develop our persona and get a clearer understanding of the problems.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Surveys</span>
                    </div>
                    <p>We first conducted a survey answered by 53 participants, all who have made a purchase at UNIQLO before.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="surveyResults" src={survey}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. About half the participants shop at UNIQLO 4 times per year <br></br>
                        2. UNIQLO's brand is known for being high quality, affordable, innovative, functional<br></br>
                        3. Only 5.7% of participants use the UNIQLO Mobile App to make a purchase<br></br>
                        4. The top app features that participants use would be to browse for products, promotions and scanning of membership barcode at checkout
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Interviews</span>
                    </div>
                    <p>From the survey, we found out that customers of UNIQLO would not necessarily be users of the mobile app or website. The survey results also point out that only 5.7% of the mobile app users and 9.7% of the desktop users eventually make a purchase on the online store. We saw a business opportunity to increase conversions and decided to conduct user interviews to find out more about the challenges users face when using the app and website.</p>

                    <p>Due to the time constraint, we interviewed 6 participants who shops at UNIQLO at least 4 times a year.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>We recorded down the interviews and drew insights from affinity mapping, which will be described and further elaborated below.</p>
                </Grid>

            </Grid>

            {/* Define */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>2 - Define</h2>
                    <p>We then synthesized our insights that we got from the user surveys and interviews by conducting affinity mapping. We utilized Mural, an online collaboration tool, as our team was working remotely.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Affinity Mapping</span>
                    </div>
                    <p>We grouped the sticky notes according to similar pain points and themes, coming up with "I" statements which eventually helped us in coming up with the problem statement and hypothesis and the development of our persona.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="affinityMap" src={affinityMap}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Persona</span>
                    </div>
                    <p>Based on our research synthesis, our team defined our persona - Mandy Tan. This helps to better empathize with our main user group and redesign the mobile app and website according to her needs.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="persona" src={persona}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Problem Statement</span>
                    </div>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="problemStatement" src={problemStatement}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Competitive Analysis</span>
                    </div>
                    <p>The brands used in our Competitive Analysis were brands identified from our user surveys and mentioned in the user interviews. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="competitiveFeature" src={competitiveFeature}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>Our team conducted a Feature Inventory on the mobile apps of the different brands.
                    Although UNIQLO might have features such as "Store Locator", and "Check In-Store Availability", these features might not have been executed as well as the other brands. Analyzing the other features that the mobile apps have, we could look into including a "finding a product via barcode" and a "add item to Wishlist" feature in the redesign.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="competitivePlusDelta" src={competitivePlusDelta}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>The striking difference between both apps was the minimalistic look from H&M's app, making is easy to browse through categories, as compared to that of UNIQLO's. From this comparison, we would analyze the pluses of H&M and work on the deltas for UNIQLO. </p>
                </Grid>

                 <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Comparative Analysis</span>
                    </div>
                    <p>Additionally, we did a Comparative Analysis across brands outside of the fashion industry. These brands were also brands which our persona usually shops at. We decided to compare the features these brands have on their mobile app.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparativeAnalysis" src={comparativeAnalysis}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>Based on the Comparative Analysis, we are able to identify the features which UNIQLO could look into incorporating. IKEA and Luiga both have a "Find Product Via Barcode" which makes it convenient for in-store users. Sephora and IKEA also incorporated Augmented Reality (AR) technology into their apps, allowing users to either visualize makeup on their face or furniture in their house respectively. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Heuristic Evaluation</span>
                    </div>
                    <p>Before redesigning the mobile app and website, we administered a Heuristic Evaluation on the current app to test whether the mobile app is user-friendly. My team members and I were the evaluators and we set aside a few hours to walk through the product ourselves. We put ourselves in our persona - Mandy's perspective and thought of the journey she might take through the app as she tries to accomplish specific tasks.

                    The evaluation itself was performed using the heuristic evaluation usability method, based on heuristics provided by Jakob Neilsen.This method consists of evaluators comparing a predefined set of usability principles to an application or website while attempting to complete a system task. </p>

                    <p>We defined the 5 main tasks which our persona might use the mobile app to complete:<br></br><br></br>
                    1. Search for a Specific Category<br></br>
                    2. Look for special deals and promotions<br></br>
                    3. Check the in-store size availability of a product<br></br>
                    4. Online exclusive products and sizes<br></br>
                    5. Look for barcode to be scanned at the cashier</p>

                    <p>To further examine the impact of each of the problems, we estimated both its severity in terms of usability principles and the ease with which the problem might be solved.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="heauristicEvaluation_scheme2" src={heauristicEvaluation_scheme2}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        Through the evaluation, we discovered some key problems affecting usability of the app and came up with recommendations on how we could rectify them. It was interesting to match the problems to the heuristic(s) as it gave us a better understanding of the guidelines of what makes an app or website usable.
                    </p>

                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="he_Success" src={he_Success}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="he_Success" src={he_Problem}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Usability Testing</span>
                    </div>
                    <p>We then conducted a usability test on the current UNIQLO app. This allowed us to spot the "obvious" errors before engaging in user testing sessions which is often time-consuming. With the more obvious problems discovered in the Heuristic Evaluation, we hoped that the usability test can validate and support our findings.
                    </p>
                    <p>We conducted the usability test with 6 participants, 3 who have used the UNIQLO app before and 3 whom have not. The tasks given were the same as the tasks used in the Heuristic Evaluation above. The sessions were conducted remotely, where our participants shared their screens, allowing us to observe their interactions.</p>
                    <p>The metrics used to evaluate the current app was:<br></br><br></br>
                    1. Easiness of each task using Single Ease Question (SEQ)<br></br>
                    2. Qualitative feedback<br></br>
                    3. System Usability Scale for overall satisfaction.
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. There were multiple ways of getting to the 'T-Shirts' section, and no single easy way. Therefore our team would work on improving the product navigation.<br></br>
                        2. Although all of our users managed to navigate to the 'Sales' Category, some of our users felt that it was not intuitive for 'Sales' to be located under 'Featured'.<br></br>
                        3. Users felt that the "Check In-Store Availability" button was not clickable due to its color.<br></br>
                        4. It was clear to users that the membership barcode would be located under 'My Account'.<br></br>
                        5. Users were confused about the in-app website and had difficulties navigating back to the native app.
                    </p>

                </Grid>
            </Grid>

            {/* Design */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>3 - Design</h2>
                    <p>Here's the exciting part, kicking off the design process! Before we could come up with the sketches and wireframes, we first had to do a feature prioritization and design the information architecture. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Feature Prioritization</span>
                    </div>
                    <p>Utilizing the MoSCoW Framework on the existing and potential new features, we prioritized the important features which we should have in our app. </p>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="moscow_existing" sx={{width: '50%'}} src={moscow_existing}></img>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="moscow_new" sx={{width: '50%'}} src={moscow_new}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        We kept the basic features of an e-commerce app after cross-referencing with other competitor mobile apps. We categorized the 'News' section as a Should Have, as it is important and add significant value. However, the 'News' Section could be revamped in the next release and might not be the focus in the first redesign. We have also decided to remove the In-App Games as none of the users in the surveys indicated value from this feature.</p>
                        <p>
                        For potential new features, we categorized features which we would solve our user's needs such as an Online Fitting Room, which would help our user to visualize the product item on him/ her, and serve as a good size guide. We brainstormed on some solutions such as a store map with GPS tracking, a ticketed Fitting Room/ Cashier and a Self Check Out feature to tackle the in-store crowd issue. However, these features would require in-store integration across all outlets, which would require a lot of effort and time. Thus, we have chosen not to include these features in our redesign, but are plausible features to consider in the future.
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img"alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Information Architecture</span>
                    </div>
                    <p>I started off with a Hybrid Card Sort - I had some pre-defined categories and also allowed participants to create their own as well. What I was hoping to achieve was to understand how users label and sort the 'cards' (which were the different pages and features of the app) - helping me create the site map of the mobile app. Having a Hybrid Card Sort also helped me in determining whether the current labels used in the current app were intuitive or if new categories should be created.</p>

                    <p>We conducted the Card Sort with 14 participants.Firstly, I standardized the categories by grouping similarly named categories with similar contents.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>The standardized categories we got were:<br></br><br></br>
                        1. About UNIQLO<br></br>
                        2. Cart<br></br>
                        3. Home<br></br>
                        4. My Account<br></br>
                        5. Online Store<br></br>
                        6. Settings</p>

                    <p>Next, we looked for distinct groups from the Similarity Matrix. This tells us which pieces of content would be suitable to be grouped together within a particular category.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="IA_Similaritymatrix" src={IA_Similaritymatrix}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Site Map</span>
                    </div>
                    <p>Based on the site map of the current app, we realized that there are many repeated pieces of content with unclear navigation. For instance, FAQ appears 2 instances under 'Home', in 'My Account' and 'Store'. This navigation might be confusing for users and affect the app's usability.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="siteMap_Existing" src={siteMap_Existing}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>Because new features will be integrated, I came up with a new site map based on the Card Sort analysis and looking at how competitor's organize the different content in their app, to ensure a smooth user experience.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="siteMap_New" src={siteMap_New}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Flow</span>
                    </div>
                    <p>The site map helped in visualizing how users might flow in the new UNIQLO app. I came up with two user flows, mapping out the 'happy path'. This allowed me to understand the decisions a user might have to make when using the app, and gave me room to evaluate and optimize the user experience. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="userFlow1" src={userFlow1}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="userFlow2" src={userFlow2}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Sketches - Design Studio</span>
                    </div>
                    <p>Our team held a mini design studio to brainstorm on how the new features - Online Fitting Room, Locating Product In-Store, Checking In-Store Availability and Making Product Reservation might look like. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Virtual Fitting Room</span>
                    </div>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="sketch1" src={sketch1}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>In-store product locator</span>
                    </div>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="sketch2" src={sketch2}></img>
                </Grid>

                 <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Style Guide</span>
                    </div>
                    <p>In order to ensure seamless integration with the current UNIQLO app, we came up with a moodboard and UI Pattern Library to ensure consistency throughout the mobile app, tablet and website. We kept the color Red as it is UNIQLO's brand color, and the colors black, grey and white would give the app and website an overall minimalistic look.</p>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="moodboard" src={moodboard}></img>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="styleGuide" src={styleGuide}></img>
                </Grid>

            </Grid>

            {/* Deliver */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>4 - Deliver</h2>
                    <p>During this stage, we were ready to come up with wireframes and conducted usability tests to validate whether our new designs would solve the problem. We iterated twice and came up with a responsive design for UNIQLO's mobile app, tablet and website.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Initial Wireframes</span>
                    </div>
                    <p>We integrated the interviews, heuristic evaluation and feedback on the current app into mid-fidelity wireframes.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="initialWireframes" sx={{width: '50%'}} src={initialWireframes}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Second Usability Test</span>
                    </div>
                    <p>We supplemented the initial wireframes with interactions using Axure. The usability test was conducted with 6 participants with 5 tasks in total, allowing us to obtain feedback for further iterations.</p>

                    <p>The tasks are as follows:<br></br><br></br>
                        1. Locate All Tops Section<br></br>
                        2. Use Virtual Fitting Room & Add to Wishlist<br></br>
                        3. Check In-Store Avalability<br></br>
                        4. Make reservation to try product in-store<br></br>
                    5. Locate Membership Barcode</p>
                    <p>
                    These are the metrics to measure the success of the initial wireframes:<br></br><br></br>

                    1. Easiness of each task using Single Ease Question (SEQ)<br></br>
                    2. Qualitative feedback<br></br>
                    3. System Usability Scale for overall satisfaction<br></br>
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. Users had concerns with the credibility of whether a picture would accurately inform them about their size<br></br>
                        2. Some users might not be comfortable with the app capturing an image of them<br></br>
                        3. Users would prefer to add product that they "tried on" virtually to wishlist immediately<br></br>
                        4. Some users took some time to find the 'wishlist' icon, as it was small in size<br></br>
                        5. Users thought that the process of finding an outlet carrying a product in the right size was not intuitive<br></br></p>

                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Iteration 2</span>
                    </div>
                    <p>After gathering the findings from the previous usability test, we made changes to the wireframes and flow of the screens. We conducted a second usability test on Prototype 2 of our mobile app, with 6 participants (different from the initial group of participants) and 5 tasks (similar to tasks used on the initial wireframes).
                    </p>

                    <a href="https://b7aoy2.axshare.com/#id=f8vvzi&p=2_home&g=1" target="blank">View our second prototype</a>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. Some users did not notice the 'recommended size' which we have included<br></br>
                        2. Users would want to be able to upload pictures of themselves using the Virtual Fitting Room<br></br>
                        3.The hanger was not very obvious in the Virtual Fitting Room, due to its size and color<br></br>
                        4. Users would need an initial on-boarding before entering the Virtual Fitting Room<br></br>
                        5. Users were still confused about the process of finding an outlet carrying a product in their size (the placement of buttons were confusing)</p>

                    <p>Though we have made pretty significant changes based on the feedback gathered on the first usability test, the second usability test was extremely helpful in revealing the small vulnerabilities in the new features which we incorporated into the UNIQLO app. We were able to discover constructive feedback regarding the Virtual Fitting Room and 'Locate Product In-Store' feature, which helped us improve on these functions in the third version of our prototype. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="seqSusScore" src={seqSusScore}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Flow</span>
                    </div>
                    <p>Here's a comparison of the average SEQ and SUS scores for each tasks for the three rounds of Usability Tests which we conducted. From this, we can tell that the average SEQ scores have improved for the last two rounds of testings, from the first test. Almost all the tasks saw an increase in average SEQ scores in the last round of usability testing, except for a slight decrease when users had to make a reservation to try a product in-store. </p>
                    <p>This is a feature that needed further improvements and we are confident that by incorporating the feedback gathering in the last round of testing, we would improve the usability of this feature.
                    </p>
                    <p>We also noticed a slight drop in the average SUS scores for the UT 3, as compared to UT 2. This was interesting as we had expected the SUS score to improve, however, we realized that this score would be affected as we conducted the tests with different sets of participants across. Nevertheless, we worked on improving usability in Prototype 3 by making changes to certain features.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Final Mockup</span>
                    </div>
                    <p>We were excited to come up with the mockups as we felt like we had a clearer idea of the new features and how we could improve the usability of the mobile app. The major change we incorporated for this version, would be the reservation of products in-store. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Responsive Design</span>
                    </div>
                    <p>Besides coming up with the mobile app, we also had to come up with the tablet and desktop versions. Our team decided to keep the mobile view and tablet similar, except for resizing certain elements. As for the desktop version, we did not include the Product Barcode Scanner and Membership Barcode as these were features to be used in-store. </p>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_availabilityReservation" src={mockup_availabilityReservation}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_fittingRoom" src={mockup_fittingRoom}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_homePage" src={mockup_homePage}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_ProductBarcodeScanner" src={mockup_ProductBarcodeScanner}></img>
                </Grid>
            </Grid>

            <Grid sx={{textAlign: "left", paddingTop: "20px", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top" backgroundColor="#f1f1f1">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Key Takeaways</h3>
                    <p>I definitely learnt a lot through the process!
                    Firstly, a challenge we faced would be collaboration in a remote setting. As most of the processes and steps requires hands-on interaction, we had to utilize tools like mural for the Affinity Mapping, which would usually be carried out physically with Post-Its.

                    Through this project, I've also understood the benefits of usability testing in discovering the underlying usability issues for the mobile app. This enabled us to make quick iterations and improve on our final designs. </p>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Next Steps</h3>
                    <p>
                        1. Fine-tune the execution of the Online Fitting Room<br></br>
                        2. Research and develop the identified 'Could Have' features such as an in-store GPS Identification, ticketed Fitting Room/ Cashier and self check-out<br></br>
                        3. Conduct on-site usability tests with users to test the use of the UNIQLO App in-store and how it can potentially further complement the in-store experience</p>
                </Grid>
        </Grid>
        </Box>

    </section>

  )
}

export default Uniqlo
