import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import footer from '../assets/footer.png';
import linkedin from '../assets/linkedin.svg';
import github from '../assets/github.svg';

// images

function Footer() {
  return (
    <section id="footer" style={{backgroundColor: "#1F5A66", margin:"0px"}}>
      <Box style={{position: "relative"}}>
        <img alt="footer" style={{width: '100%', minHeight: '180px'}} src={footer}></img>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 5}} justifyContent="center" alignItems="top" style={{position: "absolute", bottom: "0px"}}>
          <Grid item lg={12} md={12} s={6} xs={6}>
             <a href="https://www.linkedin.com/in/trishatanws/" target="blank"><img alt="linkedin" src={linkedin}></img></a>

             <a href="https://github.com/trishatanws" target="blank"><img alt="github" src={github}></img></a>
          </Grid>
          {/* <Grid item lg={3} md={6} s={12} xs={12}>
             <img alt="github" src={github}></img>
          </Grid> */}
          <Grid item lg={12} md={12} s={6} xs={6}>
             <p style={{color: "#1F5A66"}}>© 2023 Trisha Tan Wenshi</p>
          </Grid>
        </Grid>


      </Box>


    </section>
  )
}

export default Footer
