import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Wave from 'react-wavify'
import sun from '../assets/sun.PNG';

function Home() {
  return (
    <section id="home" style={{backgroundColor:'#fffff2'}}>

        <Box style={{paddingLeft: '30px', paddingRight: '30px'}}>
          <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 2 }} justifyContent="center" alignItems="center">
            <Grid item lg={4} md={4} s={12} xs={12}>
              <img alt="sun" style={{height: '320px', textAlign: 'right'}} src={sun}></img>
            </Grid>
            <Grid item lg={7} md={8} s={12} xs={12} sx={{textAlign: 'left'}}>
              <h1 style={{color:'#E5735C', marginBottom: '0'}}>Hi, I'm Trisha Tan!</h1>
              <p>A remote freelancer based in Sunny Singapore ☀️  I dabble in <span style={{color: '#E5735C', fontWeight: 700}}>UX design, web development and product marketing!</span> I love meeting with new people and forming genuine connections. Though information is so readily available with a click of a button, I feel that I learn the most during my interactions with people.

              If I'm not at my desk, you'd probably either find me at the gym or playing with my two cats 🐱‍💻</p>

              <div class="buttons">
                <a href="#work">
                <button class="blob-btn">
                  My Work
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                </a>
                <br/>

              <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                  <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo"></feColorMatrix>
                    <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                  </filter>
                </defs>
              </svg>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Wave fill='#2E8799'
          paused={false}
          options={{
            height: 20,
            amplitude: 45,
            speed: 0.21,
            points: 5
          }}
        />
      </Box>

    </section>
  )
}

export default Home
