import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './Uniqlo.css';

import tool from '../assets/tool.png';
import lightbulb from '../assets/lightbulb.png';

import catSocratesPoster from '../assets/catSocrates/catSocratesPoster.png';
import affinityMap from '../assets/catSocrates/affinityMap.png';
import persona from '../assets/catSocrates/persona.png';
import problemStatement from '../assets/catSocrates/problemStatement.png';
import solutionStatement from '../assets/catSocrates/solutionStatement.png';
import competitiveFeature from '../assets/catSocrates/competitiveFeature.png';
import competitive_element from '../assets/catSocrates/competitive_element.png';
import comparativeAnalysis from '../assets/catSocrates/comparativeAnalysis.png';
import similarityMatrix from '../assets/catSocrates/similarityMatrix.png';
import sitemap from '../assets/catSocrates/sitemap.png';
import userFlow1 from '../assets/catSocrates/userFlow1.png';
import userFlow2 from '../assets/catSocrates/userFlow2.png';
import sketch1 from '../assets/catSocrates/sketch1.jpeg';
import sketch2 from '../assets/catSocrates/sketch2.jpeg';

import survey from '../assets/uniqlo/survey.png';




import competitivePlusDelta from '../assets/uniqlo/competitive_PlusDelta.png';

import heauristicEvaluation_scheme2 from '../assets/uniqlo/HeauristicEvaluation_scheme2.png';
import he_Success from '../assets/uniqlo/HE_Success.png';
import he_Problem from '../assets/uniqlo/HE_Problem.png';
import moscow_existing from '../assets/uniqlo/MOSCOW_existingfeatures.png';
import moscow_new from '../assets/uniqlo/MOSCOW_newfeatures.png';






import moodboard from '../assets/uniqlo/moodboard.png';
import styleGuide from '../assets/uniqlo/styleGuide.png';
import initialWireframes from '../assets/uniqlo/initialWireframes.png';
import seqSusScore from '../assets/uniqlo/seqSusScore.png';
import mockup_availabilityReservation from '../assets/uniqlo/mockup_availabilityReservation.png';
import mockup_fittingRoom from '../assets/uniqlo/mockup_fittingRoom.png';
import mockup_homePage from '../assets/uniqlo/mockup_homePage.png';
import mockup_ProductBarcodeScanner from '../assets/uniqlo/mockup_ProductBarcodeScanner.png';


function CatSocrates() {
  return (
    <section>

        <Box className="box" sx={{ flexGrow: 1, paddingTop: '60px'}}>
            <Grid sx={{textAlign: "center"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>Cat Socrates</h2>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p style={{marginTop: "0px"}}>Removing the barriers of e-commerce one click at a time</p>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <img className="portfolio_img" alt="uniqlo" src={catSocratesPoster}></img>
                </Grid>
            </Grid>

            <Grid sx={{textAlign: "left", paddingTop: "20px", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top" backgroundColor="#f1f1f1">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Challenge</h3>
                    <p>The objective of this particular project was to identify the challenges faced by users when making a purchase online on the website, as well as to identify opportunities to increase sales online.</p>
                </Grid>
                <Grid item lg={6} md={6} s={6} xs={12}>
                    <h3 style={{margin: "0px"}}>Deliverables</h3>
                    <p>Redesign Cat Socrates' website in a span of two weeks</p>
                </Grid>

                <Grid item lg={6} md={6} s={6} xs={12}>
                    <h3 style={{margin: "0px"}}>Roles</h3>
                    <p>Individual Project<br></br>
                        UX Researcher<br></br>
                        UX Information Architect<br></br>
                        Facilitated Prototyping & Testing
                    </p>
                </Grid>
            </Grid>

            {/* Discover */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>1 - Discover</h2>
                    <p>Before jumping into redesigning the website, I first had to discover the challenges users face when making a purchase on the website. I decided to conducted User Interviews after coming with a Research Plan to help develop our persona and get a clearer understanding of how I could improve on the website.</p>
                    <p>Here are some research questions I had:<br></br><br></br>
                        What considerations do people take when making a purchase online?<br></br>
                        What are the common challenges people face when they shop online?<br></br>
                        What encourages people to make a purchase online?
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Interviews</span>
                    </div>
                    <p>I interviewed 5 participants who purchased crafts and items from small local retail shops. It's also worth to mention that out of the 5 participants, 3 have been to Cat Socrates.</p>

                    <p>Here are a sample of my interview questions:<br></br><br></br>
                        1. How often do you find yourself purchasing from/ browsing through local retail shops?<br></br>
                        2. Do you online shop a lot? If so, how was your experience?<br></br>
                        3. Could you walk me through the process when you browse through a website/ make a purchase online?<br></br>
                        4. Do you face any challenges when online shopping?<br></br>
                    </p>

                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>I recorded down the interviews and drew insights from the affinity map, which will be described and further elaborated below.</p>
                </Grid>

            </Grid>

            {/* Define */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>2 - Define</h2>
                    <p>Having gathered some information from the interview, I synthesized our insights that we got from the  interviews by carrying out affinity mapping. I wrote down insights from each participants and organized the sticky notes according to similar pain points and themes, coming up with "I" statements. These statements helped us in coming up with the problem statement and hypothesis, eventually leading to the development of our persona.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Affinity Mapping</span>
                    </div>
                    <p>These "I" statements informed me on the needs and frustrations that a user faces when shopping at the physical store or online. This tells me what users would expect from the website and how I could ensure that my redesign would be able to meet their needs and solve these frustrations. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="affinityMap" src={affinityMap}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Persona</span>
                    </div>
                    <p>Based on our research synthesis, I came up with a persona - Sarah, to better empathize with our main user group and redesign a user-centered website.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="persona" src={persona}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Problem Statement</span>
                    </div>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="problemStatement" src={problemStatement}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="solutionStatement" src={solutionStatement}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Competitive Analysis</span>
                    </div>
                    <p>The brands used in the Competitive Analysis were brands which participants mentioned in the interviews. </p>
                    <p>
                        By conducting a feature inventory, I was able to compare the features which Cat Socrates had and the features which were lacking from the current website. The current website does not allow users to do a predictive search, which reduces product discoverability. Through the competitive analysis, I was able to brainstorm on the possible new features which I could add into the redesign.
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="competitiveFeature" src={competitiveFeature}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p>I then did an Element Analysis on the different websites, comparing the contents of the Landing Page, Primary Navigation and Footer. From this, I was able to analyze the way different contents are organized in the different websites, giving me a better idea on how I can better categorize Cat Socrates current landing page and navigation. </p>
                </Grid>


                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="competitiveFeature" src={competitive_element}></img>
                </Grid>

                 <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Comparative Analysis</span>
                    </div>
                    <p>Additionally, we did a Comparative Analysis across brands outside of the fashion industry. These brands were also brands which our persona usually shops at. We decided to look at the features that these brands have on their website.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparativeAnalysis" src={comparativeAnalysis}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>From this, I learnt that they all have a predictive search functionality, faceted navigation for products and a 'Sort By' features. Therefore, I decided to include these three features into the redesign of Cat Socrate's website.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Usability Testing</span>
                    </div>
                    <p>In the process of discovering which features to implement, I proceeded on to conduct a usability test with 3 participants, 1 whom had visited Cat Socrates. The sessions were conducted remotely, where my participants shared their screen, allowing me to observe their interactions.
                    </p>

                    <p>The tasks given to them were:<br></br><br></br>
                    1. You would like to find a gift for your friend, who enjoys reading books about cats<br></br>
                    2. You would like to add a card game to your cart to reach the minimum for free shipping
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. Users felt that the 'Search' function in the website did not yield relevant results as they were not key word specific and does not show relevant results<br></br>
                        2. Users were also not able to filter products (Eg. Not able to filter by the genre or type of books to browse)<br></br>
                        3. The labelling of product categories could be improved as users did not know which category a card game might be in<br></br>
                    </p>

                </Grid>
            </Grid>

            {/* Design */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>3 - Design</h2>
                    <p>Having defined the problem, I would need to design a user-centered solution. Other than designing the visual aspect of the website, I had to consider how I would structure and organize the content, as Cat Socrates has an extensive range of products. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img"alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Information Architecture</span>
                    </div>
                    <p>To designing the information architecture, I conducted an Open Card Sort, which gives participants the freedom to label the cards as they see fit, even allowing them to create a category for products which they were unsure of how to categorize.</p>

                    <p>We conducted the Card Sort with 14 participants. I used Optimal Workshop to conduct the Card Sort as this was done remotely instead of in a physical setting with Post Its. As this was my first time using a tool like Optimal Sort, I had to first learn how to use the tool and optimize the analytics. Surprisingly, its a really good and user-friendly tool, allowing me to utilize it and deploy my Card Sort in a short amount of time. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>The standardized categories we got were:<br></br><br></br>
                        1. Accessories<br></br>
                        2. Bags & Pouches<br></br>
                        3. Books<br></br>
                        4. Cards<br></br>
                        5. Daily Uses<br></br>
                        </p>

                    <p>Next, I looked for distinct clusters to know which items would be good to group together based on the Similarity Matrix. The Similarity Matrix is a representation of pair combinations - giving me insights into the cards participants paired together in the same group the most often. The darker the blue, the more often they were paired by participants.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="similarityMatrix" src={similarityMatrix}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Site Map</span>
                    </div>
                    <p>Based on the Open Card Sort, Competitive and Comparative analysis, I came up with a new site map to ensure products are easily discoverable and users have a smooth interaction with the website. </p>
                </Grid>


                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="sitemap" src={sitemap}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Flow</span>
                    </div>
                    <p>The site map was useful in  visualizing the flow which users might take to accomplish certain tasks. My two main objectives was to improve the flow for Product Discoverability and the Checkout Process, thus I came up with the "happy path' which users might take. This allowed me to understand the decisions a user might have to make when using the website, and gave me room to evaluate and optimize the user experience. </p>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="userFlow1" src={userFlow1}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="userFlow2" src={userFlow2}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Sketches</span>
                    </div>
                    <p>I like using paper sketches as these allow me to make quick iterations and carry out a quick visualization of the structure of the website.  </p>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="sketch1" src={sketch1}></img>
                </Grid>


                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="sketch2" src={sketch2}></img>
                </Grid>

            </Grid>

            {/* Deliver */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>4 - Deliver</h2>
                    <p>During this stage, I came up with initial wireframes and was eager to carry out usability tests to validate the success of my designs. I iterated and came up with the mockups for redesign of Cat Socrates website.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Initial Wireframes</span>
                    </div>
                    <p>I used Sketch to make the initial wireframes, focusing on the pages involved in Product Discoverability and the Checkout Process.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="initialWireframes" sx={{width: '50%'}} src={initialWireframes}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Second Usability Test</span>
                    </div>
                    <p>I supplemented the initial wireframes with interactions using inVision. The usability test was conducted with 3 participants with 5 tasks in total, allowing us to obtain feedback for further iterations.</p>

                    <p>The tasks are as follows:<br></br><br></br>
                        1. You would like to find a book about cats. Show me how you would do it<br></br>
                        2. You would like to find a book about cats within your budget of $20. Show me how you would do it<br></br>
                        3. You would like to purchase the book. Show me how you would do it
                    </p>

                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. Users had some confusion in the checkout page (Eg. Some found the placement of the 'Continue Shopping' arrow confusing)<br></br>
                        2. There should not be a 'Remove' option once users have decided to check out as this might cause confusion<br></br>
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Mockup</span>
                    </div>
                    <p>I was excited to create the initial mockups as I felt that the feedback I have gotten helped improve the usability of the website.
                    </p>

                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_availabilityReservation" src={mockup_availabilityReservation}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_fittingRoom" src={mockup_fittingRoom}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_homePage" src={mockup_homePage}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_ProductBarcodeScanner" src={mockup_ProductBarcodeScanner}></img>
                </Grid>
            </Grid>

            <Grid sx={{textAlign: "left", paddingTop: "20px", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top" backgroundColor="#f1f1f1">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Key Takeaways</h3>
                    <p>There were definitely a lot of learnings points to gain from this project. In particularly, I was excited to redesign Cat Socrates' Website as I am a huge fan of cats and novelty gifts! </p>

                    <p>Firstly, a challenge I faced would be conducting interviews and usability tests remotely. As this was my first time having to do so, I had to practice a little and conduct some trial and error. </p>

                    <p>I also faced some difficulty initially with the Card Sorting Activity as it was my first time utilizing a tool like Optimal Sort. However, after doing some research and reading blogs written online, I had a better understanding on how to interpret the Card Sort results. As I was dealing with a huge set of data (due to the wide range of products Cat Socrates had), I had to focus on sifting through the different categories which users made and come up with the most intuitive categorization and labelling. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Next Steps</h3>
                    <p>
                        1. I would like to improve on the faceted navigation by looking at more e-commerce websites<br></br>
                        2. I would also like to conduct more in-depth usability tests to discover the underlying problems with my mockup</p>
                </Grid>
        </Grid>
        </Box>

    </section>

  )
}

export default CatSocrates
