import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './Uniqlo.css';

import svPoster from '../assets/blog/sv/svPoster.png';
import svPoster2 from '../assets/blog/sv/svPoster2.png';
import booth from '../assets/blog/sv/booth.jpeg';
import team from '../assets/blog/sv/team.jpeg';
import team2 from '../assets/blog/sv/team2.jpeg';
import halloween from '../assets/blog/sv/halloween.jpeg';
import campfire from '../assets/blog/sv/campfire.jpeg';
import grpPic from '../assets/blog/sv/grpPic.jpeg';


import tool from '../assets/tool.png';
import lightbulb from '../assets/lightbulb.png';
import survey from '../assets/uniqlo/survey.png';
import affinityMap from '../assets/uniqlo/affinityMap.png';
import persona from '../assets/uniqlo/persona.png';
import problemStatement from '../assets/uniqlo/problemStatement.png';
import competitiveFeature from '../assets/uniqlo/competitive_featureInventory.png';
import competitivePlusDelta from '../assets/uniqlo/competitive_PlusDelta.png';
import comparativeAnalysis from '../assets/uniqlo/comparativeAnalysis.png';
import heauristicEvaluation_scheme2 from '../assets/uniqlo/HeauristicEvaluation_scheme2.png';
import he_Success from '../assets/uniqlo/HE_Success.png';
import he_Problem from '../assets/uniqlo/HE_Problem.png';
import moscow_existing from '../assets/uniqlo/MOSCOW_existingfeatures.png';
import moscow_new from '../assets/uniqlo/MOSCOW_newfeatures.png';
import IA_Similaritymatrix from '../assets/uniqlo/IA_Similaritymatrix.png';
import siteMap_Existing from '../assets/uniqlo/siteMap_Existing.png';
import siteMap_New from '../assets/uniqlo/siteMap_New.png';
import userFlow1 from '../assets/uniqlo/userFlow1.png';
import userFlow2 from '../assets/uniqlo/userFlow2.png';

import sketch1 from '../assets/uniqlo/sketch1.png';
import sketch2 from '../assets/uniqlo/sketch2.png';
import moodboard from '../assets/uniqlo/moodboard.png';
import styleGuide from '../assets/uniqlo/styleGuide.png';
import initialWireframes from '../assets/uniqlo/initialWireframes.png';
import seqSusScore from '../assets/uniqlo/seqSusScore.png';
import mockup_availabilityReservation from '../assets/uniqlo/mockup_availabilityReservation.png';
import mockup_fittingRoom from '../assets/uniqlo/mockup_fittingRoom.png';
import mockup_homePage from '../assets/uniqlo/mockup_homePage.png';
import mockup_ProductBarcodeScanner from '../assets/uniqlo/mockup_ProductBarcodeScanner.png';


function SiliconValley() {
  return (
    <section>

        <Box className="box" sx={{ flexGrow: 1, paddingTop: '60px'}}>
            <Grid sx={{textAlign: "center"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>A year in Silicon Valley</h2>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p style={{marginTop: "0px"}}>I did a one year internship in a startup and here's what I learnt...</p>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <img className="portfolio_img" alt="uniqlo" src={svPoster2}></img>
                </Grid>
            </Grid>

            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p>
                        This is how my life was transformed in so many ways in terms of my outlook on life through the experiences I had in my internship. It was through this internship where I picked up so many new skills, stepped out of my comfort zone and gained independence.
                    </p>

                    <p>
                        It has also opened up my eyes to the world of tech, which was a career path I never imagined myself to be in. Stepping out of my comfort zone, I mustered the courage to solo travel and met so many amazing people along the way.
                    </p>

                    <p>
                        As part of my school's global entrepreneurial internship program, I was offered a one year internship at Wonder Workshop, an ed-tech startup with a vision to teach kids to code with robots.
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3>Internship Experience</h3>
                    <p>
                        When I first started out, I was placed in the Product Program Management department. Having no prior experience in content management,I knew I had to pick up new skills along the way to do my job well. I quickly learnt to be adaptable and learnt as much as I could by speaking to my colleagues from different departments to find out more about the products and the value we bring to our consumers. I conducted user testing in the schools around the district and helped out in exhibitions in the Children's Creativity Museum in San Francisco. I enjoyed the opportunity to share more about our products as it helped me practice my communication and presentation skills. Through this, I gained knowledge on our consumers' pain points and communicated these with the company.
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="booth" src={booth}></img>
                </Grid>


                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p>
                        As we were pushing out new products and accessories, I was in charge of making product videos to showcase their features. To be honest, I was nervous when I heard what I had to do as apart from school projects, I had never done a full video production by myself... I spent all my nights and weekends watching Youtube videos on video editing, and dabbled with Adobe after effects. At last, I finally completed it.
                    </p>

                    <p>
                        I found myself enjoying video production and slowly transitioned into the role of Product Marketing. Taking up the responsibilities of Social Media Marketing, I had full creativity in coming up with social campaigns and promotions. Content creation was something that I really enjoyed - stemming from my love for design. My boss gave me total control of copy writing and publishing posts, which meant that I had to be extra cautious and develop better decision making skills. I still remember the first time when I clicked on the 'Publish' button, I re-read the copy and scrutinized the content a dozen of times, it was truly an exhilarating experience.
                    </p>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                    <div className="video-responsive">
                        <iframe
                        width="500"
                        height="400"
                        src={`https://www.youtube.com/embed/j42HAKmYenE`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        />
                    </div>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                    <div className="video-responsive">
                        <iframe
                        width="500"
                        height="400"
                        src={`https://www.youtube.com/embed/VciHjiCvONQ`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        />
                    </div>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p>
                        Towards the end of my internship, I realised that I didn't actually utilise the concepts which I learnt in school about International Business, but I truly experienced what it meant to do business internationally. What I learnt in my International Human Resource module - I truly understood the importance of proper onboarding for international employees, how to communicate effectively with our offshore teams and cross-cultural acceptance in workplaces. I came to realise that most of our learning is done outside of school and it's through experience where we really gain knowledge. I developed a growth mindset and am always ready to take on new projects by acquiring the skills needed for it by learning them myself.
                    </p>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="team" src={team}></img>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="halloween" src={halloween}></img>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="campfire" src={campfire}></img>
                </Grid>

                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="grpPic" src={grpPic}></img>
                </Grid>



            </Grid>


        </Box>

    </section>

  )
}

export default SiliconValley
