import React from 'react'

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import { styled, theme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import Wave from 'react-wavify'
import { Button, CardActionArea, CardActions } from '@mui/material';

// images
import me from '../assets/me.png';
import bgImage from '../assets/homeBg.png'
import svPoster from '../assets/blog/sv/svPoster.png';


function Blog() {
    const navigate = useNavigate();

    function navigateSv() {
    navigate("siliconValley");
    }


    return (
    <section id="blog" style={{paddingTop:"50px"}}>

        <Box className="box">

        <Container>
            <div>
                <h1 style={{color: "#E5735C", marginTop: "-20px"}}>
                My Blog
                </h1>

                <Box sx={{ flexGrow: 1}}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 2 }} justifyContent="left" alignItems="space-around" textAlign="left">

                        {/* SV */}
                        <Grid item lg={4} md={5} s={12}>
                            <div onClick={navigateSv}>
                                <Card sx={{ maxWidth: 400, minHeight: 400, margin: '0px', background: '#ffff', backgroundImage:`url(${svPoster})`, backgroundSize: 'cover'}}>
                                    <CardActionArea>
                                        <CardContent sx={{color: '#ffff', paddingBottom: '0px'}}>
                                            <h3>Silicon Valley Internship</h3>
                                        </CardContent>

                                        <CardContent sx={{color: '#ffff', paddingTop: '0px'}}>
                                        <p>
                                            I embarked on a one year internship in Silicon Valley and here's what I learnt...
                                        </p>

                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </div>
                        </Grid>

                    </Grid>
                </Box>




            </div>
        </Container>
        </Box>
    </section>
  )
}

export default Blog
