import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import './Uniqlo.css';

import tool from '../assets/tool.png';
import lightbulb from '../assets/lightbulb.png';
import survey from '../assets/uniqlo/survey.png';
import affinityMap from '../assets/ucars/affinityMap.png';
import personaBuy from '../assets/ucars/personaBuy.png';
import personaSell from '../assets/ucars/personaSell.png';
import userJourney from '../assets/ucars/userJourney.png';
import problemStatementBuy from '../assets/ucars/problemStatementBuy.png';
import problemStatementSell from '../assets/ucars/problemStatementSell.png';
import solutionStatement from '../assets/ucars/solutionStatement.png';
import competitiveAnalysis from '../assets/ucars/competitiveAnalysis.png';
import competitiveSell from '../assets/ucars/competitiveSell.png';
import competitiveFeatureAnalysis from '../assets/ucars/competitiveFeatureAnalysis.png';
import competitiveFilters from '../assets/ucars/competitiveFilters.png';
import comparativeTelegram from '../assets/ucars/comparativeTelegram.png';
import comparativeWhatsapp from '../assets/ucars/comparativeWhatsapp.png';
import comparativeLiveStream from '../assets/ucars/comparativeLiveStream.png';
import comparativeLivebid from '../assets/ucars/comparativeLivebid.png';
import comparative_InterestingFeatures from '../assets/ucars/comparative_InterestingFeatures.png';
import comparativeInteretingFeaturesVirtual from '../assets/ucars/comparativeInterestingFeaturesVirtual.png';
import cardSort from '../assets/ucars/cardSort.png';
import sitemap from '../assets/ucars/sitemap.png';
import userFlow_v1 from '../assets/ucars/userFlow_v1.png';
import userFlow_v2 from '../assets/ucars/userFlow_v2.png';
import sketch_liveChat from '../assets/ucars/sketch_liveChat.jpg';
import sketch_productDiscovery from '../assets/ucars/sketch_productDiscovery.jpg';
import styleGuide from '../assets/ucars/styleGuide.png';
import seqSus from '../assets/ucars/seqSus.png';

import problemStatement from '../assets/uniqlo/problemStatement.png';
import competitiveFeature from '../assets/uniqlo/competitive_featureInventory.png';
import competitivePlusDelta from '../assets/uniqlo/competitive_PlusDelta.png';
import comparativeAnalysis from '../assets/uniqlo/comparativeAnalysis.png';
import heauristicEvaluation_scheme2 from '../assets/uniqlo/HeauristicEvaluation_scheme2.png';
import he_Success from '../assets/uniqlo/HE_Success.png';
import he_Problem from '../assets/uniqlo/HE_Problem.png';
import moscow_existing from '../assets/uniqlo/MOSCOW_existingfeatures.png';
import moscow_new from '../assets/uniqlo/MOSCOW_newfeatures.png';
import IA_Similaritymatrix from '../assets/uniqlo/IA_Similaritymatrix.png';
import siteMap_Existing from '../assets/uniqlo/siteMap_Existing.png';
import siteMap_New from '../assets/uniqlo/siteMap_New.png';
import userFlow1 from '../assets/uniqlo/userFlow1.png';
import userFlow2 from '../assets/uniqlo/userFlow2.png';
import ucarsPoster from '../assets/ucars/ucarsPoster.png';
import sketch1 from '../assets/uniqlo/sketch1.png';
import sketch2 from '../assets/uniqlo/sketch2.png';
import moodboard from '../assets/uniqlo/moodboard.png';
import initialWireframes from '../assets/uniqlo/initialWireframes.png';

import mockup_availabilityReservation from '../assets/uniqlo/mockup_availabilityReservation.png';
import mockup_fittingRoom from '../assets/uniqlo/mockup_fittingRoom.png';
import mockup_homePage from '../assets/uniqlo/mockup_homePage.png';
import mockup_ProductBarcodeScanner from '../assets/uniqlo/mockup_ProductBarcodeScanner.png';


function Ucars() {
  return (
    <section>

        <Box className="box" sx={{ flexGrow: 1, paddingTop: '60px'}}>
            <Grid sx={{textAlign: "center"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>UCars Go Mobile</h2>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <p style={{marginTop: "0px"}}>A seamless mobile experience for the automotive industry</p>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <img className="portfolio_img" alt="uniqlo" src={ucarsPoster}></img>
                </Grid>
            </Grid>

            <Grid sx={{textAlign: "left", paddingTop: "20px", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top" backgroundColor="#f1f1f1">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Challenge</h3>
                    <p>Being one of the fastest growing online car marketplaces in Singapore, UCars aim to revolutionize the automotive industry by developing an online car marketplace where dealers and consumers alike can enjoy a seamless end-to-end experience when buying and selling a new or second-hand car.
                    Having been engaged by UCars, the objective was to design an interactive mobile app to include existing features from the website (online bidding and live-streaming) and new features like Live Chat.</p>
                </Grid>
                <Grid item lg={6} md={6} s={6} xs={12}>
                    <h3 style={{margin: "0px"}}>Deliverables</h3>
                    <p>We had 3 weeks to hand over the final mockup to our client.</p>
                </Grid>

                <Grid item lg={6} md={6} s={6} xs={12}>
                    <h3 style={{margin: "0px"}}>Roles</h3>
                    <p>Worked in a team of 2 <br></br>
                        UX Researcher<br></br>
                        UX Designer<br></br>
                        Team Presenter<br></br>
                    </p>
                </Grid>
            </Grid>

            {/* Discover */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>1 - Discover</h2>
                    <p>Before we could get to work on designing the mobile app, we first had a client interview to understand the goals, challenges, needs and constraints which our client might have. </p>
                    <p>We developed a research plan in the beginning - outlining the research objectives, methodologies used, and the research questions to answer. The team decided to conduct user interviews, user surveys and analyze the analytics provided to help develop our persona and get a clearer understanding of the problems. </p>
                    <p>Here are some research questions we had:<br></br><br></br>
                        1. What are the needs and challenges of purchasing a car online?<br></br>
                        2. What are the needs and challenges of selling a car online?<br></br>
                        3. What are the general behaviors surrounding buying and selling  a car online?</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Hotjar & Google Analytics</span>
                    </div>
                    <p>Looking at current UCARS site browsing behavior on Google Analytics was very helpful in enriching our interview findings. Through recordings on hotjar, we were also able to see the typical browsing behavior of a user and notice patterns in navigation.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. 76% of users browse on mobile <br></br>
                        2. Popular browsing times are lunchtime, after work hours and weekends<br></br>
                        3. 28% of the users are 25-34 years old while 24% are 35-44 years old<br></br>
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Interviews</span>
                    </div>
                    <p>Due to the time constraint, the team interviewed 6 participants, consumers who have bought or sold cars online before.</p>
                    <p>Here's a sample of our interview questions:<br></br>
                        1. Could you walk me through the process of searching for a suitable car and finally making a purchase?<br></br>
                        2. What information would you need when browsing online for cars to purchase?<br></br>
                        3. What are some of your needs when looking for a dealer to sell your car?<br></br>
                        4. How do you think your experience could have been improved?</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>We recorded down the interviews and drew insights from affinity mapping, which will be described and further elaborated below.</p>

                </Grid>

            </Grid>

            {/* Define */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>2 - Define</h2>
                    <p>We then synthesized our insights that we got from the user surveys and interviews by conducting affinity mapping. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Affinity Mapping</span>
                    </div>
                    <p>We used Mural, an online collaboration tool to note down our observations on "sticky notes". Followed by organizing and grouping the sticky notes according to similar pain points and themes, and coming up with "I" statements.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="affinityMap" src={affinityMap}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Persona</span>
                    </div>
                    <p>We distilled our affinity map and analytics findings into the following persona, Andy Chan. We were able to better empathize with our main user group, who first tries to replace his old car with a new one. </p>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="persona" src={personaBuy}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="persona" src={personaSell}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Journey</span>
                    </div>
                    <p>
                        Our team came up with a User Journey map to visualize a customer's relationships with car marketplaces over time and across different touchpoints. This allows us to adopt a user-centric approach when designing the mobile app.
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="persona" src={userJourney}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Problem Statement</span>
                    </div>
                    <p>After gathering the findings from the research, we framed the problem statement using the goals and frustrations of our persona, Andy Chan. We then came up with a solution statement of how we can solve the current challenges.</p>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="problemStatementSell" src={problemStatementSell}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="problemStatementBuy" src={problemStatementBuy}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="solutionStatement" src={solutionStatement}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Competitive Analysis</span>
                    </div>
                    <p>The brands used in the Competitive Analysis were brands identified from the user survey and brands mentioned in the user interviews.  </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="competitiveAnalysis" src={competitiveAnalysis}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Task Analysis</span>
                    </div>
                    <p>To make the selling process more efficient for users, we aim to reduce the information needed for users when listing their car up for sale. A critical piece of information required across all platforms would be Carplate number and Owner Details. Both Carousell and SGCarMart offers free valuation of cars within 24 hours. Thus to be competitive, we should similarly aim to give users their car valuation in 24 hours or less. </p>
                    <p>We also found that Online Bidding was common among the different platforms to sell cars as Carro, MotoristSG, SGCarMart and eBayMotors encourages users to sell by bidding, where offers are ready from 1 day to an hour.</p>
                </Grid>


                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="competitiveSell" src={competitiveSell}></img>
                </Grid>



                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Feature Analysis</span>
                    </div>
                    <p>We looked at the different features which these apps have, namely Signing in, Signing up, Chat, List of saved cars and a News Feed or Notifications section. The ones highlighted in green would represent the features that are present and the ones in red are features which are absent. </p>

                    <p>To sign up for an account, Carousell requires users to sign in immediately after launching the app and the rest of the apps would get users to sign up to enjoy more features. Only Carousell allows users to directly communicate with buyers in 'Chat', which proves to be helpful in facilitating quick sales.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="competitiveFeatureAnalysis" src={competitiveFeatureAnalysis}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Filters Comparison</span>
                    </div>
                    <p>Because there are so many different factors when it comes to buying a car, we looked at the filters across all the apps. We distilled the indispensable filters to be: Price range, Depreciation, Registration and Mileage. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="competitiveFeatureAnalysis" src={competitiveFilters}></img>
                </Grid>


                 <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Comparative Analysis</span>
                    </div>
                    <p>Additionally, we did a Comparative Analysis across brands outside of the automobile industry. We looked outside of the category for inspiration for our 3 main features (Live Chat, Live Streaming and Live Bidding), and for other interesting features we might include.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparativeTelegram" src={comparativeTelegram}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparativeWhatsapp" src={comparativeWhatsapp}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparativeLivebid" src={comparativeLivebid}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparativeLiveStream" src={comparativeLiveStream}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparativeInteretingFeaturesVirtual" src={comparativeInteretingFeaturesVirtual}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="comparative_InterestingFeatures" src={comparative_InterestingFeatures}></img>
                </Grid>

            </Grid>

            {/* Design */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>3 - Design</h2>
                    <p>Before we could come up with the sketches and wireframes, we first had to design the information architecture.  </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img"alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Information Architecture</span>
                    </div>
                    <p>An Open Card Sort is a method where participants organize topics into categories that makes sense to them and help name the groups. I started off with conducting an Open Card Sort - this means allowing participants to come up with their own labels for the categories. We pulled content from the current website and new content which we were thinking of incorporating into the mobile app.</p>

                    <p>What I was hoping to achieve was to observe how participants would label and sort the 'cards' into different buckets of content, which would help in organizing the app navigation. This gave us a lot of insight on our participants' thought process and allowed us to design an intuitive information architecture. </p>

                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>We conducted the Card Sort with 15 participants - people who have previously bought cars or who are potential car buyers.</p>
                    <p>The standardized categories we got were:<br></br><br></br>
                        1. Buy<br></br>
                        2. Sell<br></br>
                        3. Go Live<br></br>
                        4. Resources<br></br>
                        5. Information<br></br>
                        6. Car Dealer<br></br>
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="cardSort" src={cardSort}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Site Map</span>
                    </div>
                    <p>Having synthesized the results from the Card Sort, we came up with the bottom app navigation consisting of tabs - Buy, Sell, Resources and Profile. We thought of including Watchlist and Chat in the top app navigation.</p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="sitemap" src={sitemap}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>User Flow</span>
                    </div>
                    <p>Having visualized the flow for users using the app, we mapped out two 'happy paths' for the successful 'Sale' and 'Purchase' of a Car. Through the user flow, we were able to foresee any problems users may face and make improvements to the user experience.  </p>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="userFlow1" src={userFlow_v1}></img>
                </Grid>

                <Grid item lg={6} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="userFlow2" src={userFlow_v2}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Sketches - Design Studio</span>
                    </div>
                    <p>Our team held a mini design studio to brainstorm on the new feature - Live Chat. After iterating with a few rounds of the design studio, we came up with our final sketches.  </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Live Chat</span>
                    </div>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="sketch_liveChat" src={sketch_liveChat}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Product Discovery</span>
                    </div>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="sketch_productDiscovery" src={sketch_productDiscovery}></img>
                </Grid>

                 <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Style Guide</span>
                    </div>
                    <p>Our client - UCars, had an existing brand guidelines in place. However, when designing the new mobile app, the client gave us the freedom to explore other possible options and style. Therefore, we came up with a new set of UI Pattern Library utilizing colors from the brand logo, and not straying too far from the existing guidelines. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="styleGuide" src={styleGuide}></img>
                </Grid>

            </Grid>

            {/* Deliver */}
            <Grid sx={{textAlign: "left", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top">

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h2 style={{margin: "0px"}}>4 - Deliver</h2>
                    <p>During this stage, we came up with our initial wireframes and quickly conducted usability tests to validate our new designs. This is an iterative process which helps us to evaluate and come up with improvements for our designs. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Initial Wireframes</span>
                    </div>
                    <p>We integrated the interviews, heuristic evaluation and feedback on the current app into high-fidelity wireframes.</p>

                    <a href="https://www.figma.com/proto/Up0MCHw5sq5itM6gieTIvY/UXDI-UCARS-Project?node-id=147-6773&viewport=1308%2C296%2C0.1370880901813507&scaling=scale-down" target="blank">View our high-fidelity prototype here</a>
                </Grid>

                {/* <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="initialWireframes" sx={{width: '50%'}} src={initialWireframes}></img>
                </Grid> */}

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Usability Test</span>
                    </div>
                    <p>We supplemented the initial wireframes with interactions in Figma. The usability test was conducted with 6 participants with 5 tasks in total, allowing us to obtain feedback for further iterations.</p>

                    <p>The goal of the Usability Tests is to identify usability problems, collect quantitative and qualitative data and determine the participant’s satisfaction with the app. During the test, participants will try to complete a set of tasks while we watch, listen and take notes.</p>

                    <p>The tasks are as follows:<br></br><br></br>
                    1. Check how much you can sell your car for<br></br>
                    2. Sell your car through Live Bidding<br></br>
                    3. Find out more about the Toyota Wish<br></br>
                    4. Schedule a video call with the seller<br></br>
                    5. Purchase the Toyota Wish<br></br>
                    </p>

                    <p>
                    These are the metrics to measure the success of the initial wireframes:<br></br><br></br>

                    1. Easiness of each task using Single Ease Question (SEQ)<br></br>
                    2. Qualitative feedback<br></br>
                    3. System Usability Scale for overall satisfaction<br></br>
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. Users would explore listings in the ‘Buy’ tab of similar car models to get a ballpark estimate<br></br>
                        2. Users want to know the status of their Live Bid for transparency<br></br>
                        3. A feature comparison tool for users would help facilitate comparison across different cars<br></br>
                        4. Users were unsure how to schedule a video call with the seller<br></br>
                        5. The option to take the conversation offline with suggestions to “Schedule a test drive” instead of purchasing the car immediately would be beneficial for users
                    </p>

                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Iteration 2</span>
                    </div>
                    <p>After gathering the findings from the previous usability test, we made changes to the wireframes and flow of the screens. We conducted a second usability test on Prototype 2 of our mobile app, with 6 participants and 5 tasks (similar to tasks used on the initial wireframes).
                    </p>

                    <a href="https://www.figma.com/proto/Up0MCHw5sq5itM6gieTIvY/UXDI-UCARS-Project?node-id=308-417&viewport=939%2C919%2C0.15739521384239197&scaling=scale-down" target="blank">View our second iteration here</a>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>
                        1. A couple of users didn’t tap the valuation tool because they either did not see it, or didn’t understand how it worked.<br></br>
                        2. Users were unsure how a novel feature like live bidding works<br></br>
                        3. Users want to be able to access information about their car buyer from the chat screen<br></br>
                        4. Users's would expect to see the car description before they decide to use the loan calculator<br></br>
                        5. Users wanted an acknowledgment from the seller himself/herself rather than a system generated message
                    </p>

                    <p>The feedback from both rounds of testing gave us an excelling perspective on what users would like to see when using the mobile app. We were able to discover the problems and needs which users would have and work towards addressing them in the final mockup. </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12} sx={{textAlign: 'center'}}>
                   <img className="portfolio_img" alt="seqSus" src={seqSus}></img>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={tool}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Key Findings</span>
                    </div>
                    <p>It was interesting to analyze the SEQ and SUS as we used different groups of participants for the first and second rounds of testing. The average SEQ scores were relatively high for both UT 1 and 2, which shows that users were able to complete the tasks, despite minor issues. From both tests, we gained incredible insights on the iterations to be made in the final prototype.  </p>
                    <p>Likewise, we noticed an positive increase in the Average SUS from 83.8 to 84.6.
                    </p>
                </Grid>

                <Grid item lg={12} md={12} s={12} xs={12}>
                    <div style={{textAlign: 'left'}}>
                        <img className="portfolio_img" alt="tool" style={{verticalAlign:'middle', width: '20px'}} src={lightbulb}></img>
                        <span className="subtitle" style={{verticalAlign:'middle', paddingLeft: '10px'}}>Final Mockup</span>
                    </div>
                    <p>In the final mockup, we incorporated all the iterations and addressed the problems faced by users in the first two usability tests. We were excited to show Ucars, our client, the mockups as we felt like we had a good understanding of what the mobile app entails.  </p>
                    <a href=" https://www.figma.com/proto/Up0MCHw5sq5itM6gieTIvY/UXDI-UCARS-Project?node-id=505-4187&viewport=931%2C329%2C0.297769695520401&scaling=scale-down" target="blank">View our final mockup here</a>

                </Grid>


                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_availabilityReservation" src={mockup_availabilityReservation}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_fittingRoom" src={mockup_fittingRoom}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_homePage" src={mockup_homePage}></img>
                </Grid>
                <Grid item lg={6} md={6} s={12} xs={12} sx={{textAlign: 'left'}}>
                   <img className="portfolio_img" alt="mockup_ProductBarcodeScanner" src={mockup_ProductBarcodeScanner}></img>
                </Grid>
            </Grid>

            <Grid sx={{textAlign: "left", paddingTop: "20px", paddingBottom: "20px", marginTop: "40px"}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2}} justifyContent="space-between" alignItems="top" backgroundColor="#f1f1f1">
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Key Takeaways</h3>
                    <p>I definitely enjoyed working on this project as it was something out of my comfort zone. What I got out of this project was a deeper understanding of the automobile industry and the challenges faced by car marketplaces.</p>
                    <p>It was exciting to work on this project as the app was simultaneously being worked on by the engineers as we designed the app. I am definitely looking forward to seeing the app launched in the market!</p>
                    <p>Through this project, I've also gained experience in client management and ways to facilitate better communication especially in "new normal". I learnt the importance of early communication with stakeholders and would definitely carry this knowledge on to future projects.</p>
                </Grid>
                <Grid item lg={12} md={12} s={12} xs={12}>
                    <h3 style={{margin: "0px"}}>Next Steps</h3>
                    <p>
                        1. The comparison tool on the home screen can be fleshed out<br></br>
                        2. It would be good to explore the options of integration more flexible modes of payment like credit cards to be accepted
                    </p>
                </Grid>
        </Grid>
        </Box>

    </section>

  )
}

export default Ucars
